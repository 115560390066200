/*
 * BrightLabs Proprietary Limited (c) 2021
 * https://www.brightlabs.co.bw
 * info@brightlabs.co.bw
 */

import React from "react";
import {Stack} from "@fluentui/react";
import Slide from 'react-reveal';

import hero from "../../images/illustrations/services_devops.svg";

import Page from "../shared/Page";
import helpers from "../../k/helpers";
import Hero from "../shared/Hero";
import ServicePage from "../shared/ServicePage";


class HeroContent extends React.Component<any, any> {

    public render() {

        const
            padding = helpers.padding();

        return (
            <Stack tokens={{childrenGap: padding}}>

                <Stack>

                    <Slide left>
                        <Stack tokens={{childrenGap: -16}}>
                            <h4>
                                {helpers.links.services.text}
                            </h4>

                            <h1>
                                {helpers.links.devops.text}
                            </h1>
                        </Stack>
                    </Slide>

                    <Slide right>
                        <h2>
                            Azure, AWS, Infrastructure and Cloud Management for your business.
                        </h2>
                    </Slide>

                </Stack>

                <Slide bottom>
                    <Stack verticalFill={true} verticalAlign={'center'} horizontalAlign={'center'}>
                        <h3>
                            We provide cloud management services, build architectures for applications in the Cloud
                            (Azure, AWS), configure CI/CD Processes and provide maintenance & monitoring services.
                        </h3>
                    </Stack>
                </Slide>

            </Stack>
        );
    }
}

class AdditionalContent extends React.Component<any, any> {
    render() {
        return null;
    }
}

export default class DevOps extends ServicePage {

    public render() {

        const
            backgroundColor = 'rgba(0, 0, 0, 0.003)',
            backgroundSize = '25%',

            title = "BrightLabs | " + helpers.links.devops.text,
            url = helpers.links.devops.url,
            description = "DevOps, CI/CD & Cloud services - Azure, AWS, Infrastructure and Cloud Management services for your business";

        return (
            <Page title={title} description={description} url={url}>

                <Hero backgroundColor={backgroundColor} backgroundSize={backgroundSize} hero={hero}>
                    <HeroContent />
                </Hero>

                <AdditionalContent />
            </Page>
        );
    }
}
