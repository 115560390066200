/*
 * BrightLabs Proprietary Limited (c) 2021
 * https://www.brightlabs.co.bw
 * info@brightlabs.co.bw
 */

import React from "react";
import {PrimaryButton, Separator, Spinner, SpinnerSize, Stack, Text, TextField} from "@fluentui/react";
import Slide from 'react-reveal';

import hero from "../../images/illustrations/contact.svg";
import msg_sent from "../../images/illustrations/misc/message_sent.svg";

import Page from "../shared/Page";
import ResizablePage from "../shared/ResizablePage";
import helpers from "../../k/helpers";
import Hero from "../shared/Hero";
import {Link} from "react-router-dom";


class HeroContent extends React.Component<any, any> {

    constructor(props: any) {
        super(props);

        this.state = {
            isValid: false,
            isBusy: false,
            email: '',
            fullName: '',
            phone: '',
            message: '',
            isSent: false
        };
    }

    onChange(variable: string, value: any) {

        const state = {};
        // @ts-ignore
        state[variable] = value;

        this.setState(state, () => {
            if(this.state.isSent) {
                this.props.onChangeHero(hero);
            }
            // @ts-ignore
            this.setState({
                isValid: this.validateEmail(),
                isSent: false
            });
        });
    }

    onSubmit() {

        const
            {onChangeHero} = this.props,
            env = process.env.NODE_ENV || "development",
            url = env === 'development' ?
                'http://localhost:8000' : '',
            // @ts-ignore
            token = document.querySelector('meta[name="csrf-token"]').content;

        if(!this.validateEmail()) {
            this.setState({
                isValid: false
            });
            return;
        }

        this.setState({
            isBusy: true
        });

        fetch(`${url}/enquiry`,
            {
                method: 'post',
                // @ts-ignore
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'X-CSRF-TOKEN': token
                },
                body: JSON.stringify({
                    email: this.state.email,
                    fullName: this.state.fullName,
                    phone: this.state.phone,
                    message: this.state.message
                })
            })
            .then(r => {
                if(r.ok) {
                    return r.json();
                } else {
                    throw new Error('An error occurred.');
                }
            })
            .then(data => {
                this.setState({
                    isBusy: false,
                    isSent: true,
                    isValid: false,
                    email: '',
                    fullName: '',
                    phone: '',
                    message: ''
                });

                if(data.success) {
                    onChangeHero(msg_sent);
                }
            })
            .catch(e => {
                console.log('Error:', e);
                this.setState({
                    isBusy: false,
                    isSent: false
                });
            });

        // (new Promise((s, e) => {
        //     window.setTimeout(s, 5000)
        // })).then(() => {
        //     this.setState({
        //         isBusy: false,
        //         isSent: true,
        //         isValid: false,
        //         email: '',
        //         fullName: '',
        //         phone: '',
        //         message: ''
        //     });
        //
        //     onChangeHero(msg_sent);
        // });

    }

    validateEmail() {
        const
            {email} = this.state,
            re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    public render() {

        const
            {isValid, isBusy, isSent} = this.state,
            padding = helpers.padding(),
            labelStyles = {fontWeight: 500, color: 'rgba(0, 0, 0, 0.8)', textAlign: 'start', textTransform: 'uppercase'},
            textfieldStyles={
                root: {
                    width: '100%'
                },
                wrapper: {
                    borderBottom: '1px solid rgba(0, 0, 0, 0.2)',
                    label: labelStyles
                }
            },
            textareaStyles={
                root: {
                    width: '100%'
                },
                wrapper: {
                    label: {...labelStyles, paddingLeft: 12}
                },
                fieldGroup: {
                    border: '1px solid rgba(0, 0, 0, 0.2)'
                }
            };

        return (
            <Stack tokens={{childrenGap: padding}}>

                <Stack>

                    <Slide left>
                        <Stack tokens={{childrenGap: -16}}>
                            <h4>
                                {helpers.links.contact.text}
                            </h4>

                            <h1>
                                {isSent ? 'Sent!' : 'Get in touch'}
                            </h1>
                        </Stack>
                    </Slide>

                    <Slide right>
                        <h2>
                            {isSent ? "Great, we'll be in touch." : 'Drop us a line below'}
                        </h2>
                    </Slide>

                </Stack>

                <Slide bottom>
                    <Stack
                        tokens={{childrenGap: padding}}
                        horizontalAlign={'start'}
                        style={{
                            background: 'white',
                            borderRadius: 4,
                            padding: padding,
                            boxShadow: 'rgba(149, 157, 165, 0.2) 0 4px 8px'
                        }}>

                        <TextField
                            label="Email:"
                            underlined={true}
                            required={true}
                            type={'email'}
                            value={this.state.email}
                            styles={textfieldStyles}
                            onChange={(ev, newValue) => {
                                this.onChange('email', newValue);
                            }} />

                        <TextField
                            label="Full Name:"
                            underlined={true}
                            value={this.state.fullName}
                            styles={textfieldStyles}
                            onChange={(ev, newValue) => {
                                this.onChange('fullName', newValue);
                            }}  />

                        <TextField
                            label="Mobile No:"
                            underlined={true}
                            value={this.state.phone}
                            styles={textfieldStyles}
                            onChange={(ev, newValue) => {
                                this.onChange('phone', newValue);
                            }}  />

                        <TextField
                            label="Message:"
                            multiline={true}
                            value={this.state.message}
                            styles={textareaStyles}
                            onChange={(ev, newValue) => {
                                this.onChange('message', newValue);
                            }} />

                        <Stack style={{width: '100%'}} horizontal={true} horizontalAlign={'space-between'} verticalAlign={'center'}>
                            <PrimaryButton
                                disabled={!isValid || isBusy}
                                text={'Submit'}
                                styles={{root: {borderRadius: 4}, label: {fontWeight: 400}}}
                                onClick={this.onSubmit.bind(this)} />

                            {
                                isBusy &&
                                <Spinner size={SpinnerSize.large} />
                            }
                        </Stack>

                        <Separator styles={{root: {width: '100%'}}} />

                        <Text variant={'small'} style={{textAlign: 'start'}}>
                            For information on how we use the data you provide,
                            please refer to our <Link to={'/privacy-policy'} style={{textDecoration: 'underline', color: 'inherit'}}>Privacy Policy</Link>.
                        </Text>

                    </Stack>
                </Slide>

                <Stack>

                    <Separator />

                    <h3
                        style={{color: 'rgba(0, 0, 0, 0.6)'}}>
                        (+267) 311 6744 <br />
                        info@brightlabs.co.bw <br />
                        Unit 4D Pinnacle Park, Gaborone, Botswana
                    </h3>

                    <Separator />

                </Stack>

            </Stack>
        );
    }
}

class AdditionalContent extends React.Component<any, any> {
    render() {
        return null;
    }
}

export default class Contact extends ResizablePage {

    constructor(props: any) {
        super(props);

        this.state = {
            hero: hero
        };
    }

    onChangeHero(hero: any) {
        this.setState({
            hero: hero
        });
    }

    public render() {

        const
            {hero} = this.state,
            backgroundColor = 'rgba(0, 0, 0, 0.003)',
            backgroundSize = '20%',

            title = "BrightLabs | " + helpers.links.contact.text,
            url = helpers.links.contact.url,
            description = "Get in touch with BrightLabs " +
                "- UX/UI design, custom web and mobile development, Office 365.";

        return (
            <Page title={title} description={description} url={url}>

                <Hero backgroundColor={backgroundColor} backgroundSize={backgroundSize} hero={hero} extendPage={true}>
                    <HeroContent onChangeHero={this.onChangeHero.bind(this)} />
                </Hero>

                <AdditionalContent />
            </Page>
        );
    }
}
