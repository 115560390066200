/*
 * BrightLabs Proprietary Limited (c) 2021
 * https://www.brightlabs.co.bw
 * info@brightlabs.co.bw
 */

import React from "react";
import {Stack} from "@fluentui/react";
import Slide from 'react-reveal';

import Page from "../shared/Page";
import ResizablePage from "../shared/ResizablePage";

import helpers from "../../k/helpers";

import hero from "../../images/illustrations/about.svg";
import Hero from "../shared/Hero";


class HeroContent extends React.Component<any, any> {

    public render() {

        const
            padding = helpers.padding();

        return (
            <Stack tokens={{childrenGap: padding}}>

                <Stack>

                    <Slide left>
                        <Stack tokens={{childrenGap: -16}}>
                            <h4>
                                {helpers.links.about.text}
                            </h4>

                            <h1>
                                Who we are
                            </h1>
                        </Stack>
                    </Slide>

                    <Slide right>
                        <h2>
                            A software development company that will help your business grow.
                        </h2>
                    </Slide>

                </Stack>

                <Slide bottom>
                    <Stack verticalFill={true} verticalAlign={'center'} horizontalAlign={'center'}>
                        <h3>
                            We are a team of top engineers. We help enterprises and startups with custom software development
                            – web and mobile applications, DevOps and maintenance services.
                        </h3>
                    </Stack>
                </Slide>

            </Stack>
        );
    }
}

class AdditionalContent extends React.Component<any, any> {
    render() {
        return null;
    }
}

export default class About extends ResizablePage {

    public render() {

        const
            backgroundColor = 'rgba(0, 0, 0, 0.003)',
            backgroundSize = '25%',

            title = "BrightLabs | " + helpers.links.about.text,
            url = helpers.links.about.url,
            description = "We are a custom software development company from Botswana that delivers " +
                "high-quality services - UX/UI design, custom web and mobile development, Office 365, Cloud, DevOps, CI/CD.";

        return (
            <Page title={title} description={description} url={url}>

                <Hero backgroundColor={backgroundColor} backgroundSize={backgroundSize} hero={hero}>
                    <HeroContent />
                </Hero>

                <AdditionalContent />
            </Page>
        );
    }
}
