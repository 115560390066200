/*
 * BrightLabs Proprietary Limited (c) 2021
 * https://www.brightlabs.co.bw
 * info@brightlabs.co.bw
 */

import React from "react";
import {PrimaryButton, Stack} from "@fluentui/react";
import {NavLink} from "react-router-dom";
import Slide from 'react-reveal';

import Page from "../shared/Page";
import ResizablePage from "../shared/ResizablePage";

import helpers from "../../k/helpers";
import hero from "../../images/illustrations/home.svg";
import Hero from "../shared/Hero";


class HeroContent extends React.Component<any, any> {

    public render() {

        const isSmallScreen = helpers.isSmallScreen();

        return (

            <Stack verticalFill={true} verticalAlign={'center'} horizontalAlign={isSmallScreen ? 'start' : 'start'} >

                <Slide left>

                    <h1>
                        Get scalable, modern and reliable <span style={{color: helpers.theme.brightlabs.green.opaque}}>custom software</span> and let your business grow.
                    </h1>

                    <h2>
                        Let's Create <span style={{color: helpers.theme.brightlabs.green.opaque}}>Amazing</span>&#174;
                    </h2>

                </Slide>

                <Slide bottom>
                    <Stack horizontal={true}>
                        <NavLink to={helpers.links.consultation.url}>
                            <PrimaryButton
                                text={helpers.links.consultation.text}
                                styles={{root: {borderRadius: 4, padding: isSmallScreen ? 20 : 24}, label: {fontWeight: 400}}} />
                        </NavLink>
                    </Stack>

                </Slide>

            </Stack>
        );
    }
}

class AdditionalContent extends React.Component<any, any> {
    render() {
        return null;
    }
}

export default class Home extends ResizablePage {

    public render() {

        const
            backgroundColor = 'rgba(0, 0, 0, 0.003)',
            backgroundSize = '40%',

            title = "BrightLabs | Custom Software Development Company, Botswana",
            url = helpers.links.home.url,
            description = "We are a custom software development company that delivers " +
                "high-quality services - UX/UI design, custom web and mobile development, Office 365, Cloud, DevOps, CI/CD.";

        return (
            <Page title={title} description={description} url={url}>

                <div className={'home-hero'}>
                    <Hero backgroundColor={backgroundColor} backgroundSize={backgroundSize} hero={hero}>
                        <HeroContent />
                    </Hero>
                </div>

                <AdditionalContent />

            </Page>
        );
    }
}
