/*
 * BrightLabs Proprietary Limited (c) 2021
 * https://www.brightlabs.co.bw
 * info@brightlabs.co.bw
 */

import React from "react";
import {Icon, Stack} from "@fluentui/react";

import hero from "../../images/illustrations/services.svg";
import helpers from "../../k/helpers";


export interface IHeroProps {
    backgroundColor: string;
    backgroundSize: string;
    hero: any;
    showMore?: boolean;
    extendPage?: boolean;
}
export default class Hero extends React.Component<IHeroProps, any> {

    public render() {

        const
            {backgroundColor, backgroundSize, hero, showMore, extendPage} = this.props,
            padding = helpers.padding(),
            pageDimensions = helpers.pageDimensions(),
            isSmallScreen = helpers.isSmallScreen(),
            pageHeight = (isSmallScreen || extendPage) ? 'auto' : Math.max(pageDimensions.height - 80, 500),
            backgroundPosition = `right ${padding}px top 50%`;

        return (
            <Stack
                className={'hero'}
                styles={{
                    root: {
                        height: pageHeight,
                        backgroundColor: backgroundColor,
                        backgroundImage: isSmallScreen ? 'none' : `url(${hero})`,
                        backgroundPosition: backgroundPosition,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: backgroundSize
                    }}}>

                <Stack
                    className={'hero-wrapper'}
                    tokens={{childrenGap: padding, padding: padding}}
                    verticalFill={true}
                    verticalAlign={'start'}
                    horizontalAlign={'center'}
                    styles={{
                        root: {
                            maxWidth: isSmallScreen ? '100%' : '620px'
                        }
                    }}>

                    {
                        isSmallScreen &&
                        <img alt={'hero'} src={hero} style={{height: helpers.pageDimensions().height * 0.3, width: '100%', maxWidth: '100%'}} />
                    }

                    {this.props.children}

                </Stack>

                {
                    !isSmallScreen && showMore &&
                    <Icon
                        iconName={'Down'}
                        className={'animated-arrow-down'}
                        styles={{
                            root: {
                                color: 'white',
                                fontWeight: 'bold',
                                fontSize: 18,
                                bottom: 16,
                                left: '50%',
                                marginLeft: -23,
                                position: 'absolute',
                                padding: 14,
                                selectors: {
                                    ':hover': {
                                        cursor: 'pointer',
                                        background: helpers.theme.brightlabs.green.opaque
                                    }
                                },
                                background: 'rgba(0, 0, 0, 0.6)',
                                borderRadius: '50%'
                            }
                        }}
                        onClick={() => {
                            window.scrollTo({
                                top: pageDimensions.height,
                                behavior: 'smooth'
                            });
                        }}/>
                }

            </Stack>
        );
    }
}
