/*
 * BrightLabs Proprietary Limited (c) 2021
 * https://www.brightlabs.co.bw
 * info@brightlabs.co.bw
 */

import React from "react";
import {PrimaryButton, Separator, Stack, Text} from "@fluentui/react";
import {Link, NavLink} from "react-router-dom";

import helpers from "../../k/helpers";

import logo from "../../images/logo/icon.svg";


class Menu extends React.Component<any, any> {

    private menuItems = [
        helpers.links.about,
        helpers.links.services,
        helpers.links.contact
    ];

    getSmallMenu() {

        return (
            <Stack className={'menu-sm'}>

                <div className={'menu-sm-wrapper'}>

                    <input className="burger-check" id="burger-check" type="checkbox" />

                    <label htmlFor="burger-check" className="burger" />

                    <Stack className={'navigation'} >

                        <Stack verticalFill={true} verticalAlign={'center'}>
                        {
                            this.menuItems.map(menuItem => {
                                return <NavLink
                                    activeClassName={'active'}
                                    to={menuItem.url} >
                                    {menuItem.text}
                                </NavLink>
                            })
                        }

                        <NavLink
                            activeClassName={'active'}
                            to={helpers.links.consultation.url} >
                            {helpers.links.consultation.text}
                        </NavLink>

                        </Stack>

                    </Stack>

                </div>

            </Stack>
        );
    }

    getBigMenu() {

        const
            padding = helpers.padding();

        return (
            <Stack
                horizontal={true}
                horizontalAlign={'end'}
                verticalAlign={'center'}
                tokens={{childrenGap: padding}}
                className={'menu-bg'}>
                {
                    this.menuItems.map(menuItem => {
                        return <NavLink
                            activeClassName={'active'}
                            to={menuItem.url}>
                            {menuItem.text}
                        </NavLink>
                    })
                }

                <NavLink to={helpers.links.consultation.url}>
                    <PrimaryButton
                        text={helpers.links.consultation.text}
                        styles={{root: {borderRadius: 4}, label: {fontWeight: 400}}} />
                </NavLink>
            </Stack>
        );
    }

    public render() {

        const
            isSmallScreen = helpers.isSmallScreen(),
            padding = helpers.padding(),
            menuHeight = isSmallScreen ? 72 : 80,
            logoHeight = isSmallScreen ? 40 : 60,
            menuPadding = `0 ${padding * 2}px`;

        return (
            <Stack
                horizontal={true}
                horizontalAlign={'space-between'}
                verticalAlign={'center'}
                className={'menu'}
                tokens={{
                    childrenGap: padding,
                    padding: menuPadding
                }}
                styles={{
                    root: {
                        height: menuHeight
                    }
                }}>

                <Link
                    to={helpers.links.home.url} style={{zIndex: 10, height: logoHeight}}>
                    <img src={logo} height={logoHeight}  alt={'logo'}/>
                </Link>

                { isSmallScreen ? this.getSmallMenu() : this.getBigMenu() }

            </Stack>
        );
    }
}

export interface IPageProps {
    title: string;
    description: string;
    url: string;
}
export default class Page extends React.Component<IPageProps, any> {

    setDocMetadata() {

        const
            {title, description} = this.props,
            url = `${window.location.origin}${this.props.url}`,
            canonical = `https://brightlabs.co.bw${this.props.url}`;

        window.document.title = title;
        // @ts-ignore
        document.querySelector('meta[name="title"]').setAttribute("content", title);
        // @ts-ignore
        document.querySelector('meta[name="description"]').setAttribute("content", description);

        // @ts-ignore
        document.querySelector('link[rel="canonical"]').setAttribute('href', canonical);

        // @ts-ignore
        document.querySelector('meta[property="og:title"]').setAttribute("content", title);
        // @ts-ignore
        document.querySelector('meta[property="og:description"]').setAttribute("content", description);
        // @ts-ignore
        document.querySelector('meta[property="og:url"]').setAttribute("content", url);

        // @ts-ignore
        document.querySelector('meta[property="twitter:title"]').setAttribute("content", title);
        // @ts-ignore
        document.querySelector('meta[property="twitter:description"]').setAttribute("content", description);
        // @ts-ignore
        document.querySelector('meta[property="twitter:url"]').setAttribute("content", url);
    }

    render() {

        const
            footerTextVariant = 'smallPlus';

        this.setDocMetadata();

        return(
            <div className={'ms-Grid page'} dir={'ltr'}>

                <Menu />

                <Stack className={'content'} >

                    {this.props.children}

                </Stack>

                <footer>

                    <Separator />

                    <Stack
                        className={'footer-wrapper'}
                        horizontal={true}
                        horizontalAlign={'space-between'}
                        verticalAlign={'center'} >

                        <Text variant={footerTextVariant}>
                            BrightLabs <span>&copy;</span> {new Date().getFullYear()}
                        </Text>

                        <Link to={'/privacy-policy'} >
                            <Text variant={footerTextVariant}>
                                Privacy Policy
                            </Text>
                        </Link>

                    </Stack>
                </footer>

            </div>
        );
    }
}
