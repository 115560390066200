/*
 * BrightLabs Proprietary Limited (c) 2021
 * https://www.brightlabs.co.bw
 * info@brightlabs.co.bw
 */

import React from "react";
import {Stack} from "@fluentui/react";
import Slide from 'react-reveal';

import hero from "../../images/illustrations/services_dev_ios.svg";

import Page from "../shared/Page";
import helpers from "../../k/helpers";
import Hero from "../shared/Hero";
import ServicePage from "../shared/ServicePage";


class HeroContent extends React.Component<any, any> {

    public render() {

        const
            padding = helpers.padding();

        return (
            <Stack tokens={{childrenGap: padding}}>

                <Stack>

                    <Slide left>
                        <Stack tokens={{childrenGap: -16}}>
                            <h4>
                                {helpers.links.services.text}
                            </h4>

                            <h1>
                                {helpers.links.ios_dev.text}
                            </h1>
                        </Stack>
                    </Slide>

                    <Slide right>
                        <h2>
                            We craft high-quality iOS & macOS applications that work flawlessly for every user.
                        </h2>
                    </Slide>

                </Stack>

                <Slide bottom>
                    <Stack verticalFill={true} verticalAlign={'center'} horizontalAlign={'center'}>
                        <h3>
                            We have a passionate and dedicated iOS development team that crafts 100% native iOS
                            and macOS applications using only Apple’s tools and frameworks.
                        </h3>
                    </Stack>
                </Slide>

            </Stack>
        );
    }
}

class AdditionalContent extends React.Component<any, any> {
    render() {
        return null;
    }
}

export default class IOSDev extends ServicePage {

    public render() {

        const
            backgroundColor = 'rgba(0, 0, 0, 0.003)',
            backgroundSize = '25%',

            title = "BrightLabs | " + helpers.links.ios_dev.text,
            url = helpers.links.ios_dev.url,
            description = "High-quality iOS & macOS application development";

        return (
            <Page title={title} description={description} url={url}>

                <Hero backgroundColor={backgroundColor} backgroundSize={backgroundSize} hero={hero}>
                    <HeroContent />
                </Hero>

                <AdditionalContent />
            </Page>
        );
    }
}
