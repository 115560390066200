

const
    theme = {
        brightlabs: {
            grey: {
                opaque: 'rgba(211, 211, 210, 1)',
                transparent: 'rgba(211, 211, 210, 0.5)',
                faint: 'rgba(211, 211, 210, 0.1)'
            },
            blue: {
                opaque: 'rgba(16, 110, 190, 1)',
                transparent: 'rgba(16, 110, 190, 0.5)',
                faint: 'rgba(16, 110, 190, 0.1)'
            },
            green: {
                opaque: 'rgba(106, 190, 67, 1)',
                transparent: 'rgba(106, 190, 67, 0.5)',
                faint: 'rgba(106, 190, 67, 0.1)'
            }
        }
    },
    links = {
        home: {
            text: 'Home',
            url: '/'
        },
        about: {
            text: 'About',
            url: '/about'
        },
        contact: {
            text: 'Contact',
            url: '/contact'
        },
        consultation: {
            text: 'Free Consultation',
            url: '/consultation'
        },
        services: {
            text: 'Services',
            url: '/services'
        },
        web_dev: {
            text: 'Web Development',
            url: '/services/web-development'
        },
        custom_dev: {
            text: 'Custom Software Development',
            url: '/services/custom-software-development'
        },
        o365_dev: {
            text: 'Office 365 App Development',
            url: '/services/o365-app-development'
        },
        android_dev: {
            text: 'Android App Development',
            url: '/services/android-app-development'
        },
        ios_dev: {
            text: 'IOS App Development',
            url: '/services/ios-app-development'
        },
        devops: {
            text: 'DevOps & Cloud',
            url: '/services/devops-and-cloud'
        },
        design: {
            text: 'Product Design & Workshops',
            url: '/services/product-design-and-workshops'
        }
    },
    helpers = {

        theme: theme,

        links: links,

        padding(): number {
            return helpers.isSmallScreen() ? 16 : 32;
        },

        isSmallScreen() : boolean {
            return (window.document.documentElement.clientWidth || window.document.body.clientWidth) < 720;
        },

        pageDimensions() {
            return {
                height: window.document.documentElement.clientHeight || window.document.body.clientHeight,
                width: window.document.documentElement.clientWidth || window.document.body.clientWidth
            };
        }
    };

export default helpers;
