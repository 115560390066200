/*
 * BrightLabs Proprietary Limited (c) 2021
 * https://www.brightlabs.co.bw
 * info@brightlabs.co.bw
 */

import React from "react";


export default class ResizablePage extends React.Component<any, any> {

    constructor(props: any) {
        super(props);
        this.state = {
            refresh: false
        };
    }

    componentDidMount(): void {
        this.onResize.bind(this);
        window.addEventListener('resize', this.onResize.bind(this));
        this.onResize();
    }

    componentWillUnmount(): void {
        window.removeEventListener('resize', this.onResize.bind(this));
    }

    onResize() {
        const {refresh} = this.state;
        this.setState({
            refresh: !refresh
        });
    }

}
