import React from 'react';
import {loadTheme} from '@fluentui/react';
import {initializeIcons} from '@uifabric/icons';
import {Route, Switch} from "react-router-dom";

import "../styles/App.scss";

import Home from "./home/Home";
import About from "./about/About";
import Services from "./services/Services";
import Contact from "./contact/Contact";
import Consultation from "./consultation/Consultation";

import helpers from "../k/helpers";
import WebDev from "./services/WebDev";
import CustomDev from "./services/CustomDev";
import DevOps from "./services/DevOps";
import ProductDesign from "./services/ProductDesign";
import AndroidDev from "./services/AndroidDev";
import IOSDev from "./services/IOSDev";
import O365Dev from "./services/O365Dev";
import Privacy from "./policies/Privacy";


initializeIcons();


function App() {

    loadTheme({
        defaultFontStyle: {fontFamily: `Poppins, "Century Gothic", "CenturyGothic", "century-gothic", "AppleGothic", sans-serif`},
        palette: {themePrimary: helpers.theme.brightlabs.green.opaque}
    });

  return (

      <Switch>

          {/* Privacy Policy */}
          <Route path={'/privacy-policy'}>
              <Privacy />
          </Route>

          {/* Consultation */}
          <Route path={helpers.links.consultation.url}>
              <Consultation />
          </Route>

          {/* Contact */}
          <Route path={helpers.links.contact.url}>
              <Contact />
          </Route>

          {/* Services */}
          <Route path={helpers.links.web_dev.url}>
              <WebDev />
          </Route>

          <Route path={helpers.links.custom_dev.url}>
              <CustomDev />
          </Route>

          <Route path={helpers.links.o365_dev.url}>
              <O365Dev />
          </Route>

          <Route path={helpers.links.android_dev.url}>
              <AndroidDev />
          </Route>

          <Route path={helpers.links.ios_dev.url}>
              <IOSDev />
          </Route>

          <Route path={helpers.links.devops.url}>
              <DevOps />
          </Route>

          <Route path={helpers.links.design.url}>
              <ProductDesign />
          </Route>

          <Route path={helpers.links.services.url}>
              <Services />
          </Route>

          {/* About */}
          <Route path={helpers.links.about.url}>
              <About />
          </Route>

          {/* Home */}
          <Route path={helpers.links.home.url}>
              <Home />
          </Route>

      </Switch>

  );
}

export default App;
