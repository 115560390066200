/*
 * BrightLabs Proprietary Limited (c) 2021
 * https://www.brightlabs.co.bw
 * info@brightlabs.co.bw
 */

import ResizablePage from "./ResizablePage";


export default class ServicePage extends ResizablePage {

    componentDidMount(): void {
        super.componentDidMount();

        window.scrollTo(0, 0);
    }
}
